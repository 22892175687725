import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`aaron nunley`, `justin jewett`, `rocket society`]}
    />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p>
            Rocket Society was a full-stack web development firm founded in 2000
            and disbanded in 2013. While the founders of Rocket Society have
            moved on to other opportunties and no longer actively take on new
            contracts, they maintain this domain for the vanity email addresses
            and the occasional collaborative project. You will find the contact
            information for the humans responsible for Rocket Society below.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-md-6">
          <h6>Justin Jewett</h6>
          <ul className="list-unstyled">
            <li>
              <a href="mailto:justin@rocketsociety.com">
                justin@rocketsociety.com
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/justin-jewett-a42ab894/">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/rocketsociety/">Instagram</a>
            </li>
          </ul>
        </div>
        <div className="col-md-6 pt-5 pt-md-0">
          <h6>Aaron Nunley</h6>
          <ul className="list-unstyled">
            <li>
              <a href="mailto:aaron@rocketsociety.com">
                aaron@rocketsociety.com
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/aaronnunley/">LinkedIn</a>
            </li>
            <li>
              <a href="https://www.instagram.com/mostlybeardy/">Instagram</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
