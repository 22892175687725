import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "./styles.scss"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <header className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>{data.site.siteMetadata.title}</h1>
              </div>
            </div>
          </div>
        </header>
        <main className="my-3">{children}</main>
        <footer className="my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                Copyright &copy; 2000-
                {new Date().getFullYear()} Rocket Society. All rights reserved.
                Ad Astra.
              </div>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
